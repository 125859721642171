import { useState,useEffect } from "react"

const Timer = () => {
   const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const countdownTime = new Date("Oct 31, 2024 18:00:00").getTime();

    const updateTimer = () => {
      const currentTime = new Date().getTime();
      const distance = countdownTime - currentTime;


      const totalHours = Math.floor(distance / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft(
        `${totalHours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    };

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer d-flex" role="timer">
      <p id="hours">{timeLeft}</p>
    </div>
  );
};

export default Timer;